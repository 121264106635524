<script setup lang="ts">
const { t: $t } = useI18n()

import { joinURL } from 'ufo'
import type { MenuOption } from 'naive-ui'

const props = defineProps<{ currentPath: string, isCollapsed?: boolean }>()

const emit = defineEmits<{ (e: 'select'): void }>()

const { canAccessPage } = useAuthorization()

const { menuItemsConfig } = useMenuConfig()
const route = computed(() => props.currentPath || '/')

function makeMenuOptionFromItemConfig(path: string, config: MenuItemConfig): MenuOption {
  let children: MenuOption[] | undefined
  const childrenToRender = config.children ? Object.entries(config.children).filter(([_, childConfig]) => childConfig.doRender) : []
  if (childrenToRender.length > 0) {
    children = childrenToRender.map(
      ([childPath, childConfig]) => makeMenuOptionFromItemConfig(joinURL(path, childPath), childConfig),
    )
  }

  const show = canAccessPage(path)

  return {
    label: config.isRouterLink ? useRouterLink(config.label, path) : config.label,
    labelText: typeof config.label === 'string' ? config.label : undefined,
    key: path,
    icon: () => useRenderIcon({ name: config.icon }),
    children,
    show,
  }
}

const menuOptions = computed((): MenuOption[] => Object.entries(menuItemsConfig.value)
  .filter(([_, config]) => config.doRender)
  .map(([path, config]) => {
    const options = [makeMenuOptionFromItemConfig(path, config)]
    if (config.putDividerBelow) {
      options.push({
        type: 'divider',
        key: `${path}-divider-below`,
      })
    }

    return options
  })
  .flat(),
)

const defaultExpandedKeys = ['machinery-creation']

function findMatchingOption(options: MenuOption[], key: string, prefixMatch = false) {
  return options.find((option) => {
    const optionKey = option.key as string | undefined
    if (!optionKey) {
      return false
    }

    if (prefixMatch) {
      if (optionKey === '/') {
        return optionKey === key
      }
      return key.startsWith(optionKey)
    }

    return optionKey === key
  })
}

const appNameForTitle = $t('appNameForTitle')

useHead({
  title: computed(() => {
    // Use app name as base title
    const title = appNameForTitle

    if (!menuOptions.value) {
      return appNameForTitle
    }

    // Adapt title based on navigation
    const currentRoute = route.value
    const rootLevelPartialMatch = findMatchingOption(menuOptions.value, currentRoute, true)

    if (!rootLevelPartialMatch) {
      return appNameForTitle
    }

    // 1. Do we have an exact top level match?
    const { key, labelText: labelTextRoot, children } = rootLevelPartialMatch
    if (key === currentRoute) {
      if (labelTextRoot) {
        return `${appNameForTitle} - ${labelTextRoot}`
      }
      return appNameForTitle
    }

    // 2. Does one of the children match? This time it needs to be exact as we only allow one level of children
    const secondLevelExactMatch = findMatchingOption(children || [], currentRoute)
    if (!secondLevelExactMatch) {
      return title
    }

    const { key: keyChild, labelText: labelTextChild } = secondLevelExactMatch
    if (keyChild === currentRoute) {
      if (labelTextRoot && labelTextChild) {
        return `${appNameForTitle} - ${labelTextRoot} - ${labelTextChild}`
      }
      if (labelTextRoot || labelTextChild) {
        return `${appNameForTitle} - ${labelTextRoot || labelTextChild}`
      }
    }

    return appNameForTitle
  }),
})
</script>

<template>
  <n-menu
    :options="menuOptions"
    :value="route"
    :default-expanded-keys="defaultExpandedKeys"
    :collapsed="isCollapsed"
    :root-indent="25"
    :indent="20"
    :collapsed-width="80"
    :collapsed-icon-size="22"
    accordion
    class="my-1"
    @update:value="emit('select')"
  />
</template>
