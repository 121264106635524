export interface MenuItemConfig {
  label: string | VNode
  icon: string
  isRouterLink: boolean
  doRender: boolean
  putDividerBelow?: boolean
  children?: Record<string, MenuItemConfig>
}

export default () => {
  const { canAccessPage, isRole, role, useremail } = useAuthorization()
  const { formattedUsernameAndRole } = useUserDataForDisplay()
  const i18n = useI18n()

  const { machinery, machineryAccessory, task, offer, inboundLogisticsTask, outboundLogisticsTask, storagePosition } = useQuery()
  const { data: machineryCounts } = machinery.counts(canAccessPage('/machinery-creation') || canAccessPage('/marketing'))
  const { data: machineryAccessoryCounts } = machineryAccessory.countsByTargetState(canAccessPage('/storage-tool-creation'))
  const { data: userTaskCounts } = task.countsAssignedToUser(!isRole('ext-storage-user', false))
  const { data: serviceProjectCounts } = offer.serviceProjectCounts(canAccessPage('/service-project'))
  const { data: toStoreCounts } = inboundLogisticsTask.toStoreCounts(canAccessPage('/external-storage-inbound/store'))
  const { data: toApproveCounts } = storagePosition.toApproveCounts(canAccessPage('/external-storage-inbound/approve'))
  const { data: toLoadCounts } = outboundLogisticsTask.toLoadCounts(canAccessPage('/external-storage-outbound/load'))

  const isMobileDevice = useIsMobileScreen()

  const renderedTaskLabel = computed(() => {
    const taskCounts = userTaskCounts.value
    if (!taskCounts) {
      return 'Meine Aufgaben (0)'
    }

    // Red if any overdue
    if (taskCounts.open.overdue > 0) {
      return h('span', { class: 'text-red-700' }, `Meine Aufgaben (${taskCounts.open.total})`)
    }

    // Yellow if any pending
    if (taskCounts.open.pending > 0) {
      return h('span', { class: 'text-orange-400' }, `Meine Aufgaben (${taskCounts.open.total})`)
    }

    // Otherwise black (should be 0 at this point)
    return `Meine Aufgaben (${taskCounts.open.total})`
  })

  const icons = useBrandIcons()
  const menuItemsConfig = computed((): Record<string, MenuItemConfig> => ({
    '/': {
      label: role.value === 'external-service-project-contractor' ? useremail.value : formattedUsernameAndRole.value,
      icon: 'material-symbols:account-circle-outline',
      isRouterLink: true,
      doRender: true,
    },
    '/reporting': {
      label: 'Reporting',
      icon: 'material-symbols:add-chart-outline',
      isRouterLink: true,
      doRender: true,
    },
    '/tasks': {
      label: renderedTaskLabel.value,
      icon: 'material-symbols:list-alt-outline-rounded',
      isRouterLink: true,
      putDividerBelow: true,
      doRender: true,
    },
    '/overview': {
      label: 'Geräteübersicht',
      icon: 'material-symbols:full-coverage-outline-rounded',
      isRouterLink: false,
      doRender: true,
      children: {
        '/machinery': {
          label: 'Geräte',
          icon: icons.machinery,
          isRouterLink: true,
          doRender: true,
        },
        '/storage-tool': {
          label: 'Lagertool',
          icon: icons.machineryAccessory,
          isRouterLink: true,
          doRender: true,
        },
        '/foreign-machinery': {
          label: 'Fremdgeräte',
          icon: 'material-symbols:location-away-outline-rounded',
          isRouterLink: true,
          doRender: true,
        },
      },
    },
    '/machinery-type/maintain': {
      label: 'Gerätetypen',
      icon: icons.machineryType,
      isRouterLink: true,
      doRender: !isMobileDevice.value,
    },
    '/machinery-creation': {
      label: 'Geräte-Anlage',
      icon: icons.machinery,
      isRouterLink: false,
      doRender: !isMobileDevice.value,
      children: {
        '/creation': {
          label: 'Anlage',
          icon: 'material-symbols:add-box-outline-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
        '/delivery': {
          label: `Übergabe (${machineryCounts.value?.inStatus.delivery ?? 'N/A'})`,
          icon: 'material-symbols:local-shipping-outline-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
        '/reception': {
          label: `Aufnahme (${machineryCounts.value?.inStatus.reception ?? 'N/A'})`,
          icon: 'material-symbols:featured-play-list-outline-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
        '/marking': {
          label: `Markierung (${machineryCounts.value?.inStatus.marking ?? 'N/A'})`,
          icon: 'material-symbols:qr-code-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
        '/approval': {
          label: `Bestätigung (${machineryCounts.value?.inStatus.approval ?? 'N/A'})`,
          icon: 'material-symbols:library-add-check-outline-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
      },
    },
    '/storage-tool-creation': {
      label: 'Lagertool-Anlage',
      icon: icons.machineryAccessory,
      isRouterLink: false,
      doRender: true,
      children: {
        '/creation': {
          label: 'Anlage',
          icon: 'material-symbols:add-box-outline-rounded',
          isRouterLink: true,
          doRender: true,
        },
        '/marking': {
          label: `Markierung (${machineryAccessoryCounts.value?.marking ?? 'N/A'})`,
          icon: 'material-symbols:qr-code-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
        '/approval': {
          label: `Bestätigung (${machineryAccessoryCounts.value?.approval ?? 'N/A'})`,
          icon: 'material-symbols:library-add-check-outline-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
      },
    },
    '/storage-tool-bundle': {
      label: 'Lagertool-Bündel',
      icon: 'material-symbols:ad-group-outline-rounded',
      isRouterLink: true,
      doRender: !isMobileDevice.value,
    },
    '/freelancer-sales': {
      label: 'Freelancer Verkauf',
      icon: 'material-symbols:group-outline',
      isRouterLink: false,
      doRender: !isMobileDevice.value,
      children: {
        '/released-machinery': {
          label: 'Freigaben',
          icon: 'material-symbols:library-add-check-outline-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
        '/sale/current/offer': {
          label: 'Offene Angebote',
          icon: icons.offer,
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
        '/sale/current/order': {
          label: 'Offene Aufträge',
          icon: icons.order,
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
        '/sale/all': {
          label: 'Angebots Historie',
          icon: 'material-symbols:history-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
      },

    },
    '/partner-sales': {
      label: 'Partner Verkauf',
      icon: 'material-symbols:handshake-outline-rounded',
      isRouterLink: false,
      doRender: !isMobileDevice.value,
      children: {
        '/released-machinery': {
          label: 'Freigaben',
          icon: 'material-symbols:library-add-check-outline-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
        '/quick-sale': {
          label: 'Schnellangebote',
          icon: 'material-symbols:quick-reorder-outline-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
      },
    },
    '/online-sales': {
      label: i18n.t('menu.onlineSales.label'),
      icon: 'material-symbols:real-estate-agent-outline-rounded',
      isRouterLink: false,
      doRender: isRole('admin'),
      children: {
        '/marketplaces': {
          label: i18n.t('menu.onlineSales.marketplaces.label'),
          icon: 'material-symbols:add-business-outline-rounded',
          isRouterLink: true,
          doRender: true,
        },
      },
    },
    '/set-overview': {
      label: 'Set',
      icon: icons.set,
      isRouterLink: true,
      doRender: !isMobileDevice.value,
    },
    '/marketing': {
      label: 'Marketing',
      icon: 'material-symbols:storefront-outline-rounded',
      isRouterLink: false,
      doRender: !isMobileDevice.value,
      children: {
        '/preparation': {
          label: `Vorbereiten (${machineryCounts.value?.withMedia.without ?? 'N/A'})`,
          icon: 'material-symbols:add-photo-alternate-outline-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
        '/edit': {
          label: `Anpassen (${machineryCounts.value?.withMedia.with ?? 'N/A'})`,
          icon: 'material-symbols:filter-outline-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
        '/storage-tool-categories': {
          label: i18n.t('marketing.storageToolCategories'),
          icon: 'material-symbols:category-outline-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
      },
    },
    '/calendar': {
      label: 'Kalender',
      icon: 'material-symbols:calendar-month-outline-rounded',
      isRouterLink: true,
      doRender: !isMobileDevice.value,
    },
    '/internal-costs': {
      label: 'Interne Kosten',
      icon: 'material-symbols:request-quote-outline-rounded',
      isRouterLink: false,
      doRender: !isMobileDevice.value,
      children: {
        '/machinery': {
          label: 'Geräte-Kosten',
          icon: icons.machinery,
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
        '/machinery-accessory': {
          label: i18n.t('internalCost.machineryAccessory.name'),
          icon: icons.machineryAccessory,
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
        '/order': {
          label: 'Auftrags-Kosten',
          isRouterLink: true,
          icon: icons.order,
          doRender: !isMobileDevice.value,
        },
      },
    },
    '/service': {
      label: 'Service',
      icon: 'material-symbols:service-toolbox-outline-rounded',
      isRouterLink: false,
      doRender: true,
      children: {
        '/machinery': {
          label: 'Geräte',
          icon: icons.machinery,
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
        '/repair-machinery': {
          label: 'Reparatur Gerät',
          icon: 'material-symbols:home-repair-service-outline-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
        '/repair-storage-tool': {
          label: 'Reparatur Lagertool',
          icon: 'material-symbols:home-repair-service-outline-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
        '/sale-order': {
          label: 'Vorbereitung Verkauf',
          icon: 'material-symbols:inactive-order-outline-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
        '/sale-issuance': {
          label: 'Ausgabe Verkauf',
          icon: 'material-symbols:outbox-outline-rounded',
          isRouterLink: true,
          doRender: true,
        },
      },
    },
    '/rental': {
      label: 'Vermietung',
      icon: icons.rental,
      isRouterLink: false,
      doRender: !isMobileDevice.value,
      children: {
        '/offer': {
          label: 'Angebote',
          isRouterLink: true,
          icon: icons.offer,
          doRender: !isMobileDevice.value,
        },
        '/order': {
          label: 'Aufträge',
          isRouterLink: true,
          icon: icons.order,
          doRender: !isMobileDevice.value,
        },
        '/completed': {
          label: 'Abgeschlossen',
          isRouterLink: true,
          icon: icons.orderCompleted,
          doRender: !isMobileDevice.value,
        },
        '/cancelled': {
          label: 'Storniert',
          isRouterLink: true,
          icon: icons.orderCancelled,
          doRender: !isMobileDevice.value,
        },
        '/pricing': {
          label: 'Preisbildung',
          isRouterLink: true,
          icon: 'material-symbols:attach-money-rounded',
          doRender: !isMobileDevice.value,
        },
      },
    },
    '/sale': {
      label: 'Verkauf',
      icon: icons.sale,
      isRouterLink: false,
      doRender: !isMobileDevice.value,
      children: {
        '/offer': {
          label: 'Angebote',
          isRouterLink: true,
          icon: icons.offer,
          doRender: !isMobileDevice.value,
        },
        '/order': {
          label: 'Aufträge',
          isRouterLink: true,
          icon: icons.order,
          doRender: !isMobileDevice.value,
        },
        '/completed': {
          label: 'Abgeschlossen',
          isRouterLink: true,
          icon: icons.orderCompleted,
          doRender: !isMobileDevice.value,
        },
        '/cancelled': {
          label: 'Storniert',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
          icon: icons.orderCancelled,
        },
      },
    },
    '/special-order': {
      label: i18n.t('menu.specialOrder.label'),
      icon: icons.specialOrder,
      isRouterLink: false,
      doRender: !isMobileDevice.value,
      children: {
        '/offer': {
          label: i18n.t('menu.specialOrder.offer.label'),
          isRouterLink: true,
          icon: icons.offer,
          doRender: !isMobileDevice.value,
        },
        '/order': {
          label: i18n.t('menu.specialOrder.order.label'),
          isRouterLink: true,
          icon: icons.order,
          doRender: !isMobileDevice.value,
        },
        '/completed': {
          label: i18n.t('menu.specialOrder.completed.label'),
          isRouterLink: true,
          icon: icons.orderCompleted,
          doRender: !isMobileDevice.value,
        },
        '/cancelled': {
          label: i18n.t('menu.specialOrder.cancelled.label'),
          isRouterLink: true,
          doRender: !isMobileDevice.value,
          icon: icons.orderCancelled,
        },
      },
    },
    '/service-project': {
      label: 'Dienstleistungen',
      icon: icons.serviceProject,
      isRouterLink: false,
      doRender: true,
      children: {
        '/planning': {
          label: 'Planung',
          isRouterLink: true,
          doRender: true,
          icon: icons.orderPlaning,
        },
        '/fitter-issuance': {
          label: 'Ausgabe Monteur',
          isRouterLink: true,
          doRender: true,
          icon: 'material-symbols:outbox-outline-rounded',
        },
        '/inquiry': {
          label: `Anfragen (${serviceProjectCounts.value?.inStatus.inquiry ?? 'N/A'})`,
          isRouterLink: true,
          doRender: true,
          icon: icons.orderInquiry,
        },
        '/offer': {
          label: `Angebote (${serviceProjectCounts.value?.inStatus.offer ?? 'N/A'})`,
          isRouterLink: true,
          doRender: true,
          icon: icons.offer,
        },
        '/order': {
          label: `Aufträge (${serviceProjectCounts.value?.inStatus.order ?? 'N/A'})`,
          isRouterLink: true,
          doRender: true,
          icon: icons.order,
        },
        '/completed': {
          label: 'Abgeschlossen',
          isRouterLink: true,
          doRender: true,
          icon: icons.orderCompleted,
        },
        '/cancelled': {
          label: 'Abgelehnt / Storniert',
          isRouterLink: true,
          doRender: true,
          icon: icons.orderCancelled,
        },
      },
    },
    '/service-project/external': {
      label: 'Dienstleistungen',
      icon: icons.serviceProject,
      isRouterLink: false,
      // Do not render this section for admins, as they have access to the main service-project tabs
      doRender: !isRole('admin'),
      children: {
        '/overview/order': {
          label: 'Aufträge',
          isRouterLink: true,
          doRender: true,
          icon: icons.order,
        },
        '/overview/completed': {
          label: 'Abgeschlossen',
          isRouterLink: true,
          doRender: true,
          icon: icons.orderCompleted,
        },
      },
    },
    '/offer-templates': {
      label: 'Angebotsvorlagen',
      icon: 'material-symbols:team-dashboard-outline',
      isRouterLink: false,
      doRender: !isMobileDevice.value,
      children: {
        '/position-templates': {
          label: 'Zusatzpositionen',
          icon: 'material-symbols:note-stack-add-outline-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
        '/position-template-bundles': {
          label: 'Positionsvorlagen',
          icon: 'material-symbols:note-stack-outline-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
      },
    },
    '/customer-overview': {
      label: 'Kundenübersicht',
      icon: icons.customer,
      isRouterLink: false,
      doRender: !isMobileDevice.value,
      children: {
        '/customers': {
          label: 'Kunden',
          icon: icons.customer,
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
        '/customer-user': {
          label: 'Kunden-Nutzer',
          icon: 'material-symbols:person-outline-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
        '/other-invoice-recipients': {
          label: 'Abw. Rechnungsempfänger',
          icon: 'material-symbols:person-add-outline-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
      },
    },
    '/customer-registration': {
      label: 'Registrierungsanfragen',
      icon: 'material-symbols:group-add-outline-rounded',
      isRouterLink: false,
      doRender: !isMobileDevice.value,
      children: {
        '/open': {
          label: 'Offene Anfragen',
          icon: 'material-symbols:person-add-outline-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
        '/completed': {
          label: 'Abgeschlossene Anfragen',
          icon: 'material-symbols:person-check-outline-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
      },
    },
    '/logistics': {
      label: 'Logistik',
      icon: icons.logistics,
      isRouterLink: false,
      doRender: !isMobileDevice.value,
      children: {
        '/issuance': {
          label: 'Ausgeben',
          icon: 'material-symbols:outbox-outline-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
        '/issued': {
          label: 'Erledigte Ausgaben',
          icon: 'material-symbols:check-circle-outline-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
        '/terminations': {
          label: 'Abmeldung',
          icon: 'material-symbols:cancel-outline-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
        '/collections': {
          label: 'Rücknahmen',
          icon: 'material-symbols:move-to-inbox-outline-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
        '/collected': {
          label: 'Erledigte Rücknahmen',
          icon: 'material-symbols:check-circle-outline-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
        '/store': {
          label: 'Einlagern',
          icon: 'material-symbols:add-home-outline-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
        '/cancelled': {
          label: 'Storniert',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
          icon: 'material-symbols:cancel-outline',
        },
      },
    },
    '/logistics-planning': {
      label: 'Dispo Planer',
      icon: 'material-symbols:map-outline',
      isRouterLink: true,
      doRender: !isMobileDevice.value,
    },
    '/delivery-vehicle': {
      label: 'Lieferfahrzeuge',
      icon: 'material-symbols:local-shipping-outline-rounded',
      isRouterLink: true,
      doRender: !isMobileDevice.value,
    },
    '/invoice': {
      label: 'Rechnungsstellung',
      icon: icons.invoice,
      isRouterLink: false,
      doRender: !isMobileDevice.value,
      children: {
        '/preparation': {
          label: 'Vorbereitung',
          icon: 'material-symbols:edit-document-outline-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
        '/payment': {
          label: 'Offene Rechnungen',
          icon: 'material-symbols:folder-open-outline-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
        '/archive': {
          label: 'Archiv',
          icon: 'material-symbols:archive-outline-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
      },
    },
    '/driver/overview': {
      label: 'Fahrten',
      icon: 'material-symbols:directions-outline-rounded',
      isRouterLink: true,
      doRender: true,
    },
    '/logistics/issuance': {
      label: 'Ausgeben',
      icon: 'material-symbols:outbox-outline-rounded',
      isRouterLink: true,
      doRender: isMobileDevice.value,
    },
    '/logistics/collections': {
      label: 'Rücknahmen',
      icon: 'material-symbols:move-to-inbox-outline-rounded',
      isRouterLink: true,
      doRender: isMobileDevice.value,
    },
    '/external-storage-administration': {
      label: 'Lageradministration',
      icon: 'material-symbols:other-houses-outline-rounded',
      isRouterLink: false,
      doRender: !isMobileDevice.value,
      children: {
        '/storage-project': {
          label: 'Lagerprojekte',
          icon: 'material-symbols:library-books-outline-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
        '/storage-user': {
          label: 'Lagernutzer',
          icon: 'material-symbols:deployed-code-account-outline-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
        '/storage-load-carrier': {
          label: 'Ladungsträger',
          icon: 'material-symbols:pallet-outline-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
        '/storage-planning': {
          label: 'Lagerdispo',
          icon: 'material-symbols:car-rental-outline-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
        '/storage-ride': {
          label: 'Lagerfahrten',
          icon: 'material-symbols:no-crash-outline-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
        '/storage-reporting': {
          label: 'Auswertungen',
          icon: 'material-symbols:add-chart-outline-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
        '/logistics-task-overview': {
          label: 'Abholung/Lieferung Übersicht',
          icon: 'material-symbols:local-shipping-outline',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
      },
    },

    '/external-storage': {
      label: 'Lagerübersicht',
      icon: 'material-symbols:warehouse-outline-rounded',
      isRouterLink: false,
      doRender: true,
      children: {
        '/storage-product': {
          label: 'Lagerartikelstamm',
          icon: 'material-symbols:tools-pliers-wire-stripper-outline',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
        '/positions-in-storage': {
          label: 'Auf Lager',
          icon: 'material-symbols:box-outline-rounded',
          isRouterLink: true,
          doRender: true,
        },
        '/used-storage-space-check': {
          label: i18n.t('usedStorageSpaceCheck.title'),
          icon: 'material-symbols:square-foot',
          isRouterLink: true,
          doRender: true,
        },
        '/retrieved-positions': {
          label: `Abgeschlossen`,
          icon: 'material-symbols:library-add-check-outline',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
      },
    },

    '/external-storage-inbound': {
      label: 'Einlagern',
      icon: 'material-symbols:move-to-inbox-outline-rounded',
      isRouterLink: false,
      doRender: true,
      children: {
        '/request': {
          label: 'Avisieren',
          icon: 'material-symbols:add-comment-outline-rounded',
          isRouterLink: true,
          doRender: true,
        },
        '/store': {
          label: `Einlagern (${toStoreCounts.value ?? 'N/A'})`,
          icon: 'material-symbols:move-to-inbox-outline-rounded',
          isRouterLink: true,
          doRender: true,
        },
        '/approve': {
          label: `Bestätigen (${toApproveCounts.value ?? 'N/A'})`,
          icon: 'material-symbols:check-box-outline-rounded',
          isRouterLink: true,
          doRender: !isMobileDevice.value,
        },
      },
    },

    '/external-storage-outbound': {
      label: 'Auslagern',
      icon: 'material-symbols:outbox-outline-rounded',
      isRouterLink: false,
      doRender: true,
      children: {
        '/request': {
          label: 'Auslagern',
          icon: 'material-symbols:box-outline-rounded',
          isRouterLink: true,
          doRender: true,
        },
        '/load': {
          label: `Aufladen (${toLoadCounts.value ?? 'N/A'})`,
          icon: 'material-symbols:local-shipping-outline-rounded',
          isRouterLink: true,
          doRender: true,
        },
      },
    },

  }))

  return { menuItemsConfig }
}
