<script lang="ts" setup>
import { useMagicKeys } from '@vueuse/core'

const isMac = computed(() => {
  if (navigator?.appVersion?.includes('Mac')) {
    return true
  }
  return false
})

const isCommandPaletteOpen = ref(false)
const { meta, ctrl } = useMagicKeys({
  passive: false,
  onEventFired(e) {
    if (e.key !== 'k') {
      return
    }
    if (isCommandPaletteOpen.value) {
      return
    }

    // Check on Mac if Meta(CMD) is pressed
    if (!meta.value && isMac.value) {
      return
    }

    // Check on other OS if CTRL is pressed
    if (!ctrl.value && !isMac.value) {
      return
    }

    e.preventDefault()
    isCommandPaletteOpen.value = true
  },
})
</script>

<template>
  <n-button class="sm:hidden" @click="isCommandPaletteOpen = true">
    <template #icon>
      <Icon name="material-symbols:search-rounded" class="text-hos-blue" />
    </template>
  </n-button>
  <n-input
    :placeholder="$t('commandPalette.search.placeholder.short')"
    readonly
    class="hidden sm:block max-w-[125px] md:max-w-[200px]"
    @click="isCommandPaletteOpen = true"
  >
    <template #prefix>
      <Icon name="material-symbols:search-rounded" class="text-hos-blue" />
    </template>

    <template #suffix>
      <ClientOnly>
        <div class="hidden lg:flex items-center gap-1">
          <n-tag size="tiny" :bordered="false" class="text-hos-blue">
            <template v-if="isMac">
              ⌘
            </template>
            <template v-else>
              Strg
            </template>
          </n-tag>
          <n-tag size="tiny" :bordered="false" class="text-hos-blue">
            K
          </n-tag>
        </div>
      </ClientOnly>
    </template>
  </n-input>

  <CommandPalettePopup v-if="isCommandPaletteOpen" v-model="isCommandPaletteOpen" />
</template>
