<script lang="ts" setup>
const isOpen = defineModel<boolean>({ default: false })

const {
  suggestions,
  searchText,
  selectedIndex,
  onKeyDown,
  scrollRef,
  isSearching,
  selectItemByIndex,
} = useCommandPalette(() => isOpen.value = false)
</script>

<template>
  <n-modal v-model:show="isOpen" @keydown="onKeyDown">
    <div class="w-[600px] rounded overflow-hidden" role="dialog" aria-modal="true">
      <div class="bg-white pt-3">
        <div class="px-3">
          <n-input
            v-model:value="searchText"
            size="large"
            :placeholder="$t('commandPalette.search.placeholder')"
            :autofocus="true"
            :loading="isSearching"
          >
            <template #prefix>
              <Icon name="material-symbols:search-rounded" />
            </template>
          </n-input>
        </div>
        <n-scrollbar ref="scrollRef" class="px-3 space-y-0.5 h-72" trigger="none">
          <div v-if="suggestions.length > 0" class="py-2">
            <CommandPaletteItem
              v-for="(item, index) in suggestions"
              :key="item.key"
              :item="item"
              :active="index === selectedIndex"
              @click="selectItemByIndex(index)"
            />
          </div>
          <div v-else class="h-40 w-full grid place-content-center">
            <Icon
              name="material-symbols:indeterminate-question-box-rounded"
              size="70px"
              class="text-hos-blue"
            />
          </div>
        </n-scrollbar>
      </div>

      <div class="flex flex-col sm:flex-row sm:items-center text-xs gap-3 sm:gap-4 bg-gray-50 p-3 border-t">
        <div class="flex items-center gap-1">
          <n-tag size="tiny">
            <Icon name="material-symbols:subdirectory-arrow-left-rounded" class="mt-0.5" />
          </n-tag>
          {{ $t('commandPalette.instructions.select.hint') }}
        </div>
        <div class="flex items-center gap-1">
          <n-tag size="tiny">
            <Icon name="material-symbols:arrow-upward-rounded" class="mt-0.5" />
          </n-tag>
          <n-tag size="tiny">
            <Icon name="material-symbols:arrow-downward-rounded" class="mt-0.5" />
          </n-tag>
          {{ $t('commandPalette.instructions.navigate.hint') }}
        </div>
        <div class="flex items-center gap-1">
          <n-tag size="tiny">
            <span>
              ESC
            </span>
          </n-tag>
          {{ $t('commandPalette.instructions.close.hint') }}
        </div>
      </div>
    </div>
  </n-modal>
</template>
